<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <esmp-select
        v-model="localValue"
        clearable
        filterable
        :placeholder="placeholder + (required ? ' *' : '')"
        @on-change="onInput"
      >
        <esmp-select-option
          v-for="item in items"
          :value="item.id"
          :key="item.id"
        >
          {{ item.label }}
        </esmp-select-option>
      </esmp-select>
      <div v-if="v.errors.length || hintText" class="hm-form__element-hint">
        {{ v.errors.length ? v.errors[0] : hintText }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';

export default {
  name: 'HmSelect',
  mixins: [uid],
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: undefined,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    // Если уже есть значение, то вызвать обновление модели и, соответственно, отображение связанных групп
    if (this.value) {
      this.onInput(this.value);
    }
  },
};
</script>
